<!-- 门禁模块--门禁详情页--通行人员 -->
<template>
  <div id="Passenger" v-loading="loading" :element-loading-text="$t('doorDetails.a1')" v-table-size="tableSize">
    <!-- 标题栏 -->
    <my-tabletitle>
      <!-- 人员信息列表（标题） -->
      {{ $t("doorDetails.a143") }}
      <template v-slot:name>
        <!-- 人员信息导出下拉菜单 -->
        <a-dropdown>
          <a-menu slot="overlay" @click="handleMenuClick">
            <!-- 当前页导出按钮 -->
            <a-menu-item :key="1">{{ $t("doorDetails.a144") }}</a-menu-item>
            <!-- 全部导出按钮 -->
            <a-menu-item :key="0">{{ $t("doorDetails.a145") }}</a-menu-item>
          </a-menu>
          <!-- 人员信息导出按钮 -->
          <a-button type="primary" ghost size="small" style="margin-right:15px"> {{ $t("doorDetails.a146") }} <a-icon type="down" /> </a-button>
        </a-dropdown>
        <!-- 显示内容 -->
        <a-popover placement="bottomRight">
          <template slot="content">
            <a-row style="width: 350px">
              <a-col v-for="(item, index) in displayContent" :key="index" :span="12">
                <a-checkbox :disabled="item.disabled" v-model="item.select">
                  {{ item.title }}
                </a-checkbox>
              </a-col>
            </a-row>
          </template>
          <template slot="title">
            <span>{{ $t("doorDetails.a125") }}</span>
          </template>
          <!-- 显示内容按钮 -->
          <a-button type="primary" size="small"> {{ $t("doorDetails.a125") }} </a-button>
        </a-popover>
      </template>
    </my-tabletitle>
    <!-- 人员信息表格 -->
    <a-table :columns="column" :dataSource="tableData" :scroll="size" :pagination="pagination" :rowKey="(record) => record.personId">
      <template slot="chaozuo" slot-scope="text, record">
        <div>
            <a-popover placement="left">
              <template slot="content">
                <!-- 删除按钮 -->
                <a-button class="table_button" type="primary" @click="() => personDelete(record)">{{ $t("doorDetails.a147") }}</a-button>
                <!-- 人员编辑按钮 -->
                <a-button class="table_button" type="primary" @click="() => infoGroupCompile(record)">{{ $t("doorDetails.a148") }}</a-button>
              </template>
              <a>{{ $t("doorDetails.a149") }}</a>
            </a-popover>
          </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import tabletitle from '../../../components/Title/tabletitle'
import { getBindPeople, exportBindPeople } from '../../../api/door'
import { tableSort, tableTitleDisplay, tableIndexDisplay} from '../../../utils/utils'

export default {
  components: {
    'my-tabletitle': tabletitle
  },
  data(){
        return{
          loading:false,
          tableData: [],
          size: { x: 2300, y: 240 },
          pagination: {
            total: 0, //数据总数
            pageSize: 30, //每页中显示10条数据
            showSizeChanger: false, //是否可以改变pagesize
            pageSizeOptions: ['5', '10', '50', '100'], //每页中显示的数据
            showTotal: (total) => `${total} ${this.$t('doorDetails.a129')}`, //分页中显示总的数据
            showQuickJumper: false, //是否可以快速跳转至某页
            defaultCurrent: 1, //默认当前页面数
            // hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
            onShowSizeChange: (current, pageSize) => {
              this.pagination.defaultCurrent = current
              this.pagination.pageSize = pageSize
              if(this.$platform.spider ==='NEW'){
                this.getTableData()
              }
            },
            onChange: (current, size) => {
              this.pagination.defaultCurrent = current
              this.pagination.pageSize = size
              if(this.$platform.spider ==='NEW'){
                this.getTableData()
              }
            }
          },
          columns: [
            {
              id: '1',
              title: 'NO',
              dataIndex: 'NO',
              customRender: (text, record, index) =>`${(this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1}`,
              width: 60,
              align: 'center',
              fixed: 'left'
            },
            {
              id: '2',
              title: this.$t('doorDetails.a150'),
              dataIndex: 'managementCode',
              sorter: (a, b) => { return tableSort(a, b, 'managementCode') },
              width: 130,
              align: 'center'
            },
            {
              id: '3',
              title: this.$t('doorDetails.a151'),
              dataIndex: 'name',
              sorter: (a, b) => { return tableSort(a, b, 'name') },
              width: 110,
              align: 'center'
            },
            {
              id: '4',
              title: this.$t('doorDetails.a152'),
              dataIndex: 'kana',
              sorter: (a, b) => { return tableSort(a, b, 'kana') },
              width: 150,
              align: 'center'
            },
            {
              id: '5',
              title: this.$t('doorDetails.a153'),
              dataIndex: 'cardFaceNo',
              sorter: (a, b) => { return tableSort(a, b, 'cardFaceNo') },
              width: 130
            },
            {
              id: '14',
              title: this.$t('doorDetails.a154'),
              dataIndex: 'nationalityName',
              sorter: (a, b) => { return tableSort(a, b, 'nationalityName') },
              width: 100,
              align: 'center'
            },
            {
              id: '6',
              title: this.$t('doorDetails.a155'),
              dataIndex: 'resistTime',
              sorter: (a, b) => { return tableSort(a, b, 'resistTime') },
              width: 130,
              align: 'center'
            },
            {
              id: '7',
              title: this.$t('doorDetails.a156'),
              dataIndex: 'related_building',
              sorter: (a, b) => { return tableSort(a, b, 'related_building') },
              align: 'center'
            },
            {
              id: '8',
              title: this.$t('doorDetails.a157'),
              dataIndex: 'personGr1',
              sorter: (a, b) => { return tableSort(a, b, 'personGr1') },
              width: 130,
              align: 'center'
            },
            {
              id: '9',
              title: this.$t('doorDetails.a158'),
              dataIndex: 'personGr2',
              sorter: (a, b) => { return tableSort(a, b, "'personGr2'") },
              width: 300,
              align: 'center'
            },
            {
              id: '10',
              title: this.$t('doorDetails.a159'),
              dataIndex: 'personGr3',
              sorter: (a, b) => { return tableSort(a, b, 'personGr3') },
              width: 200,
              align: 'center'
            },
            {
              id: '11',
              title: this.$t('doorDetails.a160'),
              dataIndex: 'personGr4',
              sorter: (a, b) => { return tableSort(a, b, "'personGr4'") },
              width: 180,
              align: 'center'
            },
            {
              id: '12',
              title: this.$t('doorDetails.a161'),
              dataIndex: 'personGr5',
              sorter: (a, b) => { return tableSort(a, b, 'personGr5') },
              width: 180,
              align: 'center'
            },
            {
              id: '13',
              title: this.$t('doorDetails.a162'),
              dataIndex: 'chaozuo',
              scopedSlots: { customRender: 'chaozuo' },
              width: 100,
              fixed: 'right',
              align: 'center'
            }
          ],
          column: [],
          displayContent: JSON.parse(localStorage.getItem('Passenger_tableTitle')) || []
        }
    },
  watch:{
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns
        const name = 'Passenger_tableTitle'
        const data = tableIndexDisplay(name, columns, newValue)
        this.column = data
      },
      deep: true,
      immediate: true
    }
  },
  mounted(){
    // 通行人员页初始化表格数据
    this.getTableData()
    // 表格表头
    const name = 'Passenger_tableTitle'
    const columns = this.columns
    const data = tableTitleDisplay(name, columns)
    if (data.columns == undefined) {
      this.displayContent = data.displayContent
    } else {
      this.column = data.columns
      this.displayContent = data.displayContent
    }
  },
  methods:{
    // 表格高度计算
    tableSize() {
        const indexDom = document.getElementById('Passenger')
        if (indexDom !== null) {
            const height = indexDom.clientHeight
            const title = document.getElementsByClassName('ant-table-thead')[0].clientHeight
            const tableHeight = height - 120 - title
            this.size.y = tableHeight
        }
    },
    // 获取表格数据
    getTableData(){
      this.loading = true
      const securityGateWithPersonParam = {
        pageNum: this.pagination.defaultCurrent,
        pageSize: this.pagination.pageSize,
        securityGateId: this.$route.query.doorId
      }
      getBindPeople(securityGateWithPersonParam)
      .then(res => {
        // console.log(res)
        this.loading = false
        const { data:{ list, total } } = res
        this.tableData = list
        this.pagination.total = total
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 获取导出人员信息 type：0全部数据导出，1当前页数据导出
    handleMenuClick(e){
      this.loading = true
      const type = e.key
      const securityGateWithPersonParam = {
        pageNum: this.pagination.defaultCurrent,
        pageSize: this.pagination.pageSize,
        securityGateId: this.$route.query.doorId
      }
      exportBindPeople(securityGateWithPersonParam,type)
      .then(res => {
        // console.log(res)
        this.loading = false
        const filename = decodeURIComponent(res.headers['content-disposition']).split(';')[1].split('=')[1]
        const file = res.data
        this.download(file,filename)
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 文件导出
    download(file,filename) {
        let url = window.URL.createObjectURL(file)
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    },
    // 人员删除功能
    personDelete(value) {
      this.$confirm({ title: `${this.$t('doorDetails.a163')} ${value.name} ?`,
        centered: true,
        onOk: () => {
          this.loading = true
          const data = {
            ids: [value.personId],
            siteId: this.siteId
          }
          personDelete(data)
            .then((res) => {
              // console.log(res);
              this.loading = false
              if (res.errorCode == '00') {
                this.$message.success(res.msg)
                let deletes = res.data.ids
                let personList = this.personList
                for (let i = 0; i < deletes.length; i++) {
                  let result = personList.findIndex(
                    (row) => row.personId === deletes[i]
                  );
                  personList.splice(result, 1)
                }
              } else if (res.errorCode !== '00') {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {
              this.loading = false
              console.log(err)
            })
        }
      })
    },
    // 人员编辑功能
    infoGroupCompile(value){
      const siteGrId = value.siteGrId
      const id = value.personId
      this.$router.push({
        path: "/homepage/door/peopleManagement",
        query: { id, siteGrId }
      })
    }
  }
}
</script>

<style scoped>
#Passenger{
  height: 100%;
  padding: 0px 20px 20px 20px;
}
.table_button{
  margin-right: 15px;
}
</style>