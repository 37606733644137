<!--门禁模块--门详情页--操作状态/故障·警报表格-->
<template>
  <div id="detailstabel">
    <table>
      <tr class="tabletitle">
        <td>{{ $t("details.a13") }}</td>
        <td>{{ $t("details.a14") }}</td>
        <td>{{ $t("details.a15") }}</td>
        <td>{{ $t("details.a13") }}</td>
        <td>{{ $t("details.a14") }}</td>
        <td>{{ $t("details.a15") }}</td>
      </tr>
      <tr class="tabletitle">
        <td>{{ $t("details.a31") }}</td>
        <td>
          <img :src="doorStatus" :title="data.doorStatus.status.subItem" />
        </td>
        <td>{{ data.doorStatus.status.changeDatetime }}</td>
        <td rowspan="2" class="nihao">{{ $t("details.a32") }}</td>
        <td rowspan="2">
          <img src="../../../../public/images/icon/531.png" alt="" />
          <img src="../../../../public/images/icon/alarm.png" alt="" />
        </td>
        <td>{{ data.ablnormalOpen.operate.changeDatetime }}</td>
      </tr>
      <tr class="tabletitle">
        <td>{{ $t("details.a33") }}</td>
        <td><img src="../../../../public/images/icon/530.png" alt="" /></td>
        <td>{{ data.temporaryUnlock.operate.changeDatetime }}</td>
        <td>{{ data.ablnormalOpen.alarm.changeDatetime }}</td>
      </tr>
      <tr class="tabletitle">
        <td>{{ $t("details.a34") }}</td>
        <td><img src="../../../../public/images/icon/alarm.png" alt="" /></td>
        <td>{{ data.tooLongOpen.alarm.changeDatetime }}</td>
        <td rowspan="2" class="nihao">{{ $t("details.a35") }}</td>
        <td rowspan="2">
          <img src="../../../../public/images/icon/531.png" alt="" />
          <img src="../../../../public/images/icon/alarm.png" alt="" />
        </td>
        <td>{{ data.invade.operate.changeDatetime }}</td>
      </tr>
      <tr class="tabletitle">
        <td rowspan="3">{{ $t("details.a36") }}</td>
        <td rowspan="3">
          <img src="../../../../public/images/icon/530.png" alt="" />
          <img src="../../../../public/images/icon/527.png" alt="" />
          <img src="../../../../public/images/icon/alarm.png" alt="" />
        </td>
        <td>{{ data.lockStatus.operate.changeDatetime }}</td>
        <td>{{ data.invade.alarm.changeDatetime }}</td>
      </tr>
      <tr class="tabletitle">
        <td>{{ data.lockStatus.status.changeDatetime }}</td>
        <td rowspan="3" class="nihao">{{ $t("details.a37") }}</td>
        <td rowspan="3">
          <img src="../../../../public/images/icon/531.png" alt="" />
          <img src="../../../../public/images/icon/alarm.png" alt="" />
        </td>
        <td>{{ data.loopFree.operate.changeDatetime }}</td>
      </tr>
      <tr class="tabletitle">
        <td>{{ data.lockStatus.alarm.changeDatetime }}</td>
        <td>{{ data.loopFree.alarm.changeDatetime }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        doorStatus: {
          status: {},
        },
        lockStatus: {
          status: {},
          alarm: {},
          operate: {},
        },
        temporaryUnlock: {
          operate: {},
        },
        ablnormalOpen: {
          alarm: {},
          operate: {},
        },
        tooLongOpen: {
          alarm: {},
        },
        invade: {
          alarm: {},
          operate: {},
        },
        loopFree: {
          alarm: {},
          operate: {},
        },
      }),
    },
  },
  computed: {
    doorStatus: function () {
      if (this.data.doorStatus.status.subItem == "关") {
        return require("../../../../public/images/icon/527.png");
      } else if (this.data.doorStatus.status.subItem == "开") {
        return require("../../../../public/images/icon/528.png");
      }
    },
  },
  methods: {},
};
</script>
<style scoped>
#detailstabel {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-bottom: 1px solid #dedfdf;
}
.tabletitle {
  border-bottom: 1px solid #dedfdf;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
.table-button {
  width: 120px;
}
img {
  width: 36px;
  margin-right: 15px;
}
.nihao {
  border-left: 1px solid #dedfdf;
}
</style>