<!-- 门禁模块--门禁详情页--通行权限 -->
<template>
  <div id="access" ref="access" v-loading="loading" :element-loading-text="$t('doorDetails.a1')">
    <div class="aside">
        <a-input-search v-model="searchValue" :placeholder="$t('doorDetails.a123')" style="width: 100%" allow-clear/>
        <div class="aside-menu">
            <div class="empty" v-if="list.length === 0">
                <a-empty/>
            </div>
            <div class="menu-container">
                <a-menu mode="inline" v-model="modeSelect" :inlineIndent="10">
                    <a-menu-item v-for="item in list" :key="item.key" @click="menuItemSelect(item)">{{ item.title }}</a-menu-item>
                </a-menu>
            </div>
        </div>
    </div>
    <div class="main">
        <div class="empty" v-if="modeSelect.length == 0">
            <a-empty />
        </div>
        <div class="main-table" v-table-size="tableSize" v-if="!modeSelect.length == 0">
            <my-tabletitle>
                {{ $t("doorDetails.a124") }}
                <template v-slot:name>
                    <a-popover placement="bottomRight">
                        <template slot="content">
                            <a-row style="width: 350px">
                                <a-col v-for="(item, index) in displayContent" :key="index" :span="12">
                                    <a-checkbox :disabled="item.disabled" v-model="item.select">
                                        {{ item.title }}
                                    </a-checkbox>
                                </a-col>
                            </a-row>
                        </template>
                        <template slot="title">
                            <span>{{ $t("doorDetails.a125") }}</span>
                        </template>
                        <a-button type="primary" size="small">{{ $t("doorDetails.a125") }}</a-button>
                    </a-popover>
                </template>
            </my-tabletitle>
            <a-table :columns="column" :dataSource="data" :scroll="size" :pagination="pagination" :rowKey="(record) => record.id"></a-table>
        </div>
        <div class="main-but" v-if="!modeSelect.length == 0">
            <a-button class="button" type="primary" style="margin-right:20px" @click="openCopy">{{ $t("doorDetails.a126") }}</a-button>
            <a-button class="button" type="primary" @click="toTerminalEditor">{{ $t("doorDetails.a127") }}</a-button>
        </div>
    </div>

    <!-- 通行模式复制对话框 -->
    <a-modal :title="$t('doorDetails.a126')" :width="300" centered :visible="visible" :getContainer="() => this.$refs.access" :destroyOnClose="true" @cancel="cancelCopyMode" v-drag>
      <template slot="footer">
        <a-button key="submit" @click="cancelCopyMode" :disabled="disabled">
          {{ $t("doorDetails.a11") }}
        </a-button>
        <a-button key="back" type="primary" @click="setCopyMode" :disabled="disabled">
          {{ $t("doorDetails.a100") }}
        </a-button>
      </template>
      <div v-loading="confirmLoading" :element-loading-text="$t('doorDetails.a1')">
        <div style="padding-bottom: 10px">
          <span>{{ $t("doorDetails.a128") }}</span>
        </div>
        <my-select style="width: 200px" :list="list" :placeholder="$t('doorDetails.a120')" @select="selectCopyKey" :isActive="isActive"/>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from '../../../components/Title/tabletitle'
import { getBindingTrafficMode, getTPData, passCopy } from '../../../api/door'
import { tableSort, tableTitleDisplay, tableIndexDisplay } from '../../../utils/utils'
import select from '../../../components/Select/select'

export default {
    components: {
        'my-tabletitle': tabletitle,
        'my-select': select
    },
    data(){
        return{
            loading:false,
            searchValue:'',
            list:[],
            searchList:[],
            modeSelect:[],
            size: { x: 1300, y: 250 },
            pagination: {
                total: 0, //数据总数
                pageSize: 30, //每页中显示10条数据
                showSizeChanger: false, //是否可以改变pagesize
                pageSizeOptions: ['5', '10', '50', '100'], //每页中显示的数据
                showTotal: (total) => `${total} ${this.$t('doorDetails.a129')}`, //分页中显示总的数据
                showQuickJumper: false, //是否可以快速跳转至某页
                defaultCurrent: 1, //默认当前页面数
                // hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
            },
            columns:[
                {
                    id: '1',
                    title: 'NO',
                    dataIndex: 'NO',
                    customRender: (text, record, index) => `${(this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1}`,
                    width: 60,
                    align: 'center'
                },
                {
                    id: '2',
                    title: this.$t('doorDetails.a130'),
                    dataIndex: 'customerName',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'customerName')
                    },
                    width: 120,
                },
                {
                    id: '3',
                    title: this.$t('doorDetails.a131'),
                    dataIndex: 'siteName',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'siteName')
                    },
                    width: 120
                },
                {
                    id: '4',
                    title: this.$t('doorDetails.a132'),
                    dataIndex: 'tenantName',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'tenantName')
                    },
                    width: 130,
                },
                {
                    id: '5',
                    title: this.$t('doorDetails.a133'),
                    dataIndex: 'name',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'name')
                    },
                    width: 130,
                },
                {
                    id: '6',
                    title: this.$t('doorDetails.a134'),
                    dataIndex: 'credentialReaderName',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'credentialReaderName')
                    },
                    width: 150,
                },
                {
                    id: '7',
                    title: this.$t('doorDetails.a135'),
                    dataIndex: 'EQUIPMENT_NAME2',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'EQUIPMENT_NAME2')
                    },
                    width: 100,
                },
                {                                                           
                    id: '8',
                    title: this.$t('doorDetails.a136'),
                    dataIndex: 'alertSet',
                    customRender: (text, record, index) =>
                        record.alertSet == 0
                        ? this.$t('doorDetails.a137')
                        : this.$t('doorDetails.a138'),
                    sorter: (a, b) => {
                        return tableSort(a, b, 'alertSet')
                    },
                    width: 100,
                },
                {
                    id: '9',
                    title: this.$t('doorDetails.a139'),
                    dataIndex: 'scheduleName',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'scheduleName')
                    },
                    width: 130,
                },
                {
                    id: '10',
                    title: this.$t('doorDetails.a140'),
                    dataIndex: 'calendarName',
                    sorter: (a, b) => {
                        return tableSort(a, b, 'calendarName')
                    },
                    width: 100,
                }
            ],
            column:[],
            data:[],
            displayContent:JSON.parse(localStorage.getItem('access_tableTitle')) || [],
            disabled:false,
            visible:false,
            confirmLoading:false,
            isActive: undefined,
            copyKey: undefined,
            accessRoleId: undefined,
        }
    },
    watch:{
        // 监听搜索框输入内容查找对应的数据
        searchValue: {
            handler(newValue) {
                let list = this.searchList
                let searchList = list.filter((row) => row.title.indexOf(newValue) !== -1)              
                this.list = searchList
            },
            deep: true
        },
        // 监听表格表头显示内容生成表格表头
        displayContent: {
            handler(newValue) {
                const columns = this.columns
                const name = 'access_tableTitle'
                const data = tableIndexDisplay(name, columns, newValue)
                this.column = data
            },
            deep: true,
            immediate: true
        },
    },
    mounted(){
        // 页面加载初始化侧边栏数据
        this.loading = true
        let data = [ this.$route.query.doorId ]
        console.log(data)
        getBindingTrafficMode(data)
            .then(res => {
                // console.log('通行权限',res)
                this.loading = false
                let { data } = res
                this.list = data.children[0].children
                this.searchList = data.children[0].children
            })
            .catch(err => {
                this.loading = false
                console.log(res) 
            })
        // 表格表头
        const name = 'access_tableTitle'
        const columns = this.columns
        const tableTitle = tableTitleDisplay(name, columns)
        if (tableTitle.columns == undefined) {
            this.displayContent = tableTitle.displayContent
        } else {
            this.column = tableTitle.columns
            this.displayContent = tableTitle.displayContent
        }
    },
    methods:{
        // 表格高度计算
        tableSize() {
            const indexDom = document.getElementById('access')
            if (indexDom !== null) {
                const height = indexDom.getElementsByClassName('main-table')[0].clientHeight
                const title = document.getElementsByClassName('ant-table-thead')[0].clientHeight
                const tableHeight = height - 120 - title
                this.size.y = tableHeight
            }
        },
        // 侧边栏选择操作
        menuItemSelect(item){
            const accessRoleId = item.key.split(",")[1]
            this.accessRoleId = accessRoleId
            const data = { siteId: this.$route.query.siteId, accessRoleId }
            this.getTableData(data)
        },
        // 表格数据
        getTableData(data){
            this.loading = true
            console.log(data)
            getTPData(data)
            .then(res => {
                console.log(res)
                this.loading = false
                const { data: { arDeviceList } } = res
                this.data = arDeviceList
            })
            .catch(err => {
                this.loading = false
            })
        },
        // 弹出模式复制对话框
        openCopy(){
            this.visible = true
        },
        // 关闭模式复制对话框
        cancelCopyMode(){
            this.visible = false
        },
        // 选择通行模式
        selectCopyKey(val) {
            this.isActive = val
            this.copyKey = val.key.split(",")[1]
        },
        // 通行模式复制的确定按钮 isActive:是否已选择通行模式
        setCopyMode(){
            if (this.isActive == undefined) {
                this.$error({
                    title: this.$t('doorDetails.a141'),
                    content: this.$t('doorDetails.a142'),
                    centered: true
                })
            } else {
                this.confirmLoading = true
                this.disabled = true
                let data = {
                    srcID: this.copyKey,
                    targetID: this.accessRoleId
                };
                passCopy(data)
                .then((res) => {
                    // console.log(res)
                    this.confirmLoading = false
                    this.disabled = false
                    if (res.errorCode == "00") {
                        this.copyKey = undefined
                        let data = {
                            siteId: this.$route.query.siteId,
                            accessRoleId: this.accessRoleId
                        };
                        this.getTableData(data)
                        this.cancelCopyMode()
                        this.$message.success(res.msg)
                    } else if (res.errorCode == "02") {
                        this.$message.error(res.msg)
                    }
                })
                .catch((err) => {
                    this.confirmLoading = false
                    this.disabled = false
                    console.log(err)
                });
            }
        },
        // 通行模式页跳转至认证终端编辑页
        toTerminalEditor() {
            this.$router.push({
                path: "/homepage/door/terminalEditor",
                query: { id: this.$route.query.siteId, accessRoleId: this.accessRoleId }
            })
        }
    }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#access{
    display: flex;
    height: 100%;
}
.aside {
  width: 250px;
  height: 100%;
  margin-right: 15px;
  padding-left: 20px;
  overflow: hidden;
}
.aside-search {
  padding: 15px 0px 15px 20px;
}
.aside-menu{
    height: calc(100% - 32px);
    position: relative;
    overflow: auto;
}
.menu-container{
    height: 100%;
    position: absolute;
    min-width: 230px;
}
.empty{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid rgba(12, 12, 12, 0.2);
}
.main{
    width: calc(100% - 265px);
    padding-bottom: 15px;
}
.main-table{
    height: calc(100% - 32px);
    padding-right: 20px;
    overflow: hidden;
}
.main-but{
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px;
}
.li-item {
  position: relative;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
  padding: 0px 20px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s;
}
.li-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #7682ce;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: 0.15s;
  content: "";
}
.li-item:hover {
  color: #7682ce;
}
.li-item-select {
  position: relative;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
  padding: 0px 20px;
  background-color: #f0f4ff;
  white-space: nowrap;
  color: #7682ce;
  cursor: pointer;
  overflow: hidden;
}
.li-item-select::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #7682ce;
  transform: scaleY(1);
  opacity: 1;
  transition: 0.15s;
  content: "";
}
</style>