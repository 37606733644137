<!--门禁模块--门详情页-->
<template>
  <div id="DoorDetails" v-loading="spinning" :element-loading-text="$t('doorDetails.a1')">
    <!-- 头部 -->
    <my-pageTitle>
      <div class="page-title">
        <div>{{ data.baseInfo.name }}</div>
        <div>
          <a-icon type="close" :style="{ fontSize: '20px', color: '#7682CE' }" @click="goDack"/>
        </div>
      </div>
    </my-pageTitle>
    <div class="info-container">
      <!-- 基础信息栏 -->
      <my-info :data="data.baseInfo" @updateName="updateName"/>
    </div>
    <!-- 分页 -->
    <a-tabs default-active-key="1">
      <!-- 操作状态 -->
      <a-tab-pane key="1" :tab="$t('doorDetails.a109')">
        <my-operateStatus/>
      </a-tab-pane>
      <!-- 设备详情 -->
      <a-tab-pane key="2" :tab="$t('doorDetails.a110')">
        <my-devicedetail :data="data.doorDetail" :type="data.type" :id="data.id"/>
      </a-tab-pane>
      <!-- 时间表设置 -->
      <a-tab-pane key="3" :tab="$t('doorDetails.a111')">
        <div class="timetable-container">
          <div>
            <my-dailySchedule :data="data"/>
          </div>
          <div class="timetable-container-top">
            <div class="timetable-container-top-left">
              <my-timetable :data="data.scheduleRecover" :type="data.type" :id="data.id"/>
            </div>
            <div class="timetable-container-top-right">
              <my-recovery :type="data.type" :id="data.id" />
            </div>
          </div>
        </div>
      </a-tab-pane>
      <!-- 通行权限 -->
      <a-tab-pane key="4" :tab="$t('doorDetails.a112')">
        <my-access/>
      </a-tab-pane>
      <!-- 通行人员 -->
      <a-tab-pane key="5" :tab="$t('doorDetails.a113')">
        <my-Passenger/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import operateStatus from './components/OperationStatus.vue'
import info from "./components/baseInfo"
import doorTable from "./components/doorTable"
import templateTitle from "./components/templateTitle"
import doorDeviceDetail from "./components/doorDeviceDetail"
import timetableResumption from "./components/timetableResumption"
import manualRecovery from "./components/manualRecovery"
import dailySchedule from './components/dailySchedule.vue'
import PageTitle from '../../components/Title/PageTitle.vue'
import access from './components/access.vue'
import Passenger from './components/Passenger.vue'
import { doorSwitch, getDoorDetails, getDoorItemStatus } from "../../api/door"
import moment from "moment";
// import { json } from 'body-parser'

export default {
  name: "DoorDetails",
  components: {
    "my-info": info,
    "my-table": doorTable,
    "my-templatetitle": templateTitle,
    "my-devicedetail": doorDeviceDetail,
    "my-timetable": timetableResumption,
    "my-recovery": manualRecovery,
    'my-dailySchedule':dailySchedule,
    'my-pageTitle':PageTitle,
    'my-operateStatus':operateStatus,
    'my-access':access,
    'my-Passenger':Passenger
  },
  data() {
    return {
      spinning: false,
      loading: false,
      bloading: false,
      changeState: false,
      data: { baseInfo:{ name:'' } },
      current: ['1']
    }
  },
  mounted() { 
    this.getData()
  },
  methods: {
    moment,
    // 页面后退
    goDack() {
      this.$router.go(-1)
    },
    // 页面开关操作方法
    onChange(checked) {
      if (checked) {
        this.loading = true
        let data = new FormData()
        data.append('id', this.$route.query.id)
        data.append('pv', this.$route.query.pv==0?1:0)  //Modified by li_yj on 2022-3-30 改为与开关状态的相反状态
        doorSwitch(data)
          .then((res) => {
            // console.log(res);
            let { errorCode } = res
            if (errorCode == '00') {
              this.loading = false
              this.$message.success(this.$t('doorDetails.a93'))
              setTimeout(() => { this.changeState = false }, 5000)
            } else if (errorCode == '02') {
              this.changeState = false
              this.loading = false
              this.$message.error(this.$t('doorDetails.a93'))
            } else {
              return false
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },
    //页面数据获取
    getData() {
      this.spinning = true
      let doorId = this.$route.query.doorId
      getDoorDetails({ doorId })
        .then((res) => {
          this.spinning = false
          let { data } = res
          this.data = data
          // console.log('门禁模块--详情页数据',res)
          for (let i in data.scheduleRecover) {
            if (typeof data.scheduleRecover[i] == 'string') { data.scheduleRecover[i] = moment(data.scheduleRecover[i], "HH:mm") }
          }
        })
        .catch((err) => {
          this.spinning = false
          console.log(err)
        });
    },
    // 基本信息名称修改成功后同时修改父组件中的名称
    updateName(data) {
      // console.log(this.data)
      this.data.baseInfo.name = data
    }
  }
}
</script>

<style scoped>
#DoorDetails {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  overflow: hidden;
}
.page-title{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-container{
  padding: 15px 20px 10px 20px;
}
div /deep/ .ant-tabs-bar{
  padding: 0px 20px;
}
div /deep/.ant-tabs{
  height: calc(100% - 139px);
}
div /deep/.ant-tabs-content{
  height: calc(100% - 60px);
  /* overflow-X: auto; */
}
.timetable-container{
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.timetable-container-top{
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.timetable-container-top-left{
  width: calc(40% - 10px);
}
.timetable-container-top-right{
  width: calc(60% - 10px);
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>