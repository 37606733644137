<!--门禁模块--门详情页--设备详情信息组件-->
<template>
  <div id="doorDeviceDetail" v-loading="loading" :element-loading-text="$t('doorDetails.a1')">
    <div class="row-title">
      <span>{{ $t("doorDetails.a19") }}</span>
    </div>
    <!-- 第一列 -->
    <div class="row-content">
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :span="4"> {{ $t("doorDetails.a20") }} </a-col>
        <a-col :span="4">
          <a-select v-model="data.scheduleID" style="width: 150px">
            <a-select-option v-for="item in data.schedules" :key="item.value" :value="item.value"> {{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4"> {{ $t("doorDetails.a21") }} </a-col>
        <a-col :span="4">
          <a-select v-model="data.calanderID" style="width: 150px">
            <a-select-option v-for="item in data.calanders" :key="item.value" :value="item.value"> {{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4"> {{ $t("doorDetails.a22") }} </a-col>
        <a-col :span="4">
          <a-input-number :min="0" :max="99" v-model="data.validTime" />
          <span>{{ $t("doorDetails.a118") }}</span>
        </a-col>
      </a-row>
    </div>
    <!-- 第二列 -->
    <div class="row-content">
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :span="4"> {{ $t("doorDetails.a23") }} </a-col>
        <a-col :span="4">
          <a-input-number :min="0" :max="3599" v-model="data.timeoutCheck" />
          <span>{{ $t("doorDetails.a119") }}</span>
        </a-col>
        <a-col :span="4"> {{ $t("doorDetails.a24") }} </a-col>
        <a-col :span="4">
          <a-radio-group name="radioGroup" v-model="data.silent">
            <a-radio :value="1">{{ $t("doorDetails.a25") }}</a-radio>
            <a-radio :value="0">{{ $t("doorDetails.a26") }}</a-radio>
          </a-radio-group>
        </a-col>
        <a-col :span="4"> {{ $t("doorDetails.a27") }} </a-col>
        <a-col :span="4">
          <a-input-number :min="0" :max="999999" v-model="data.count" />
          <span>{{ $t("doorDetails.a121") }}</span>
        </a-col>
      </a-row>
    </div>
    <!-- 第三列 -->
    <div class="row-content">
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :span="4"> {{ $t("doorDetails.a28") }} </a-col>
        <a-col :span="4">
          <a-checkbox v-model="data.countOver"></a-checkbox>
        </a-col>
        <a-col :span="4"> {{ $t("doorDetails.a29") }} </a-col>
        <a-col :span="4">
          <a-input-number :min="0" :max="999999" v-model="data.upperLimit" :disabled="disabled"/>
          <span>{{ $t("doorDetails.a121") }}</span>
        </a-col>
        <a-col :span="4"></a-col>
        <a-col :span="4"></a-col>
      </a-row>
    </div>
    <div class="row-footer">
      <a-button type="primary" style="width: 120px" ghost @click="setInfo">{{ $t("doorDetails.a12") }}</a-button>
    </div>
  </div>
</template>

<script>
import { setDoorDeviceDetail } from '../../../api/door'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: Number
    },
    id: {
      type: Number
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    disabled: function () {
      return !this.data.countOver
    },
  },
  methods: {
    // 修改设备详情信息的值
    setInfo() {
      this.$confirm({
        title: this.$t('doorDetails.a30'),
        centered: true,
        onOk: () => {
          this.loading = true
          let doorDetail = {
            calanderID: this.data.calanderID,
            count: this.data.count,
            countOver: this.data.countOver,
            scheduleID: this.data.scheduleID,
            silent: this.data.silent,
            timeoutCheck: this.data.timeoutCheck,
            upperLimit: this.data.upperLimit,
            validTime: this.data.validTime,
            id: this.id,
            type: this.type
          }
          // console.log("设备详情信息的值", doorDetail);
          setDoorDeviceDetail(doorDetail)
            .then((res) => {
              // console.log(res);
              this.loading = false
              if (res.errorCode == "00") {
                this.$message.success(res.msg)
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
#doorDeviceDetail {
  width: 100%;
  border-radius: 3px;
  white-space: nowrap;
  padding: 0px 20px 20px 20px;
  overflow: hidden;
}
.row-title {
  font-size: 18px;
  background-color: #f2f4f7;
  padding: 10px 20px;
  border: 1px solid #e8e9ea;
}
.row-content {
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #e8e9ea;
  border-top: none;
}
.row-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #e8e9ea;
  border-top: none;
}
input {
  width: 120px;
  margin-right: 10px;
}
</style>