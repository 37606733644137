<!-- 操作状态 -->
<template>
  <div id="OperationStatus" v-loading="loading" :element-loading-text="$t('doorDetails.a1')">
    <a-table :columns="columns" :data-source="data" :pagination="false" :rowKey="(record) => record.key">
      <template slot="operate" slot-scope="text,record">
        <!-- 暂时解锁--解锁 -->
        <a-button type="primary" v-if="record.key === 3" @click="OperationMethod(record,0)" >{{ $t("doorDetails.a114") }}</a-button>
        <!-- 异常开--复位 -->
        <a-button type="primary" v-if="record.key === 4" @click="OperationMethod(record,1)" >{{ $t("doorDetails.a115") }}</a-button>
        <!-- 上解锁--上锁 -->
        <a-button type="primary" v-if="record.key === 2" @click="OperationMethod(record,0)" style="margin-right:15px">{{ $t("doorDetails.a116") }}</a-button>
        <!-- 上解锁--解锁 -->
        <a-button type="primary" v-if="record.key === 2" @click="OperationMethod(record,1)" >{{ $t("doorDetails.a114") }}</a-button>
        <span v-if="record.key === 1">-</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getDoorOperationStatus, doorSwitch } from '../../../api/door'

export default {
  data(){
    return{
      loading: false,
      columns: [
        {
          title: this.$t("doorDetails.a13"),
          dataIndex: 'subItemNewDesc',
          key: '1'
        },
        {
          title: this.$t("doorDetails.a117"),
          dataIndex: 'state',
          key: '2'
        },
        {
          title: this.$t("doorDetails.a91"),
          dataIndex: 'operation',
          key: '3',
          scopedSlots: { customRender: 'operate' }
        },
        {
          title: this.$t("doorDetails.a15"),
          dataIndex: 'stateChangeTime',
          key: '4'
        }
      ],
      data: [                       // 数组长度固定为4
        { 
          project:'门状态',         // 项目名称，命名规则为: details.doorState 配合前端多语言
          state:'关闭',             // 状态
          StateChangeTime:'',       // 状态变化时间
          key:1                     // 必须的key值，用于渲染该项目的操作按钮
        },
        { project:'暂时解锁',state:'--',StateChangeTime:'',key:2 },
        { project:'异常开',state:'--',StateChangeTime:'',key:3 },
        { project:'上解锁状态',state:'上锁',StateChangeTime:'',key:4 }
      ]
    }
  },
  mounted(){
    this.getTableData()
  },
  methods:{
    // 数据初始化和更新
    getTableData(){
      this.loading = true
      const data = { doorId: this.$route.query.doorId }
      getDoorOperationStatus(data)
      .then(res => {
        console.log(res)
        const { data } = res
        const arr = data.filter(item => item.key <= 4).map((item) => {
          const operate = item.subItemNews.findIndex(index => index.pointType == 2)
          const status = item.subItemNews.findIndex(index => index.pointType == 1)
          if(status !== -1){
            item.id = item.subItemNews[status].id
            item.state = item.subItemNews[status].state
            item.stateChangeTime = item.subItemNews[status].stateChangeTime
          }else{
            item.state = '--'
            item.stateChangeTime = '----/--/-- --:--:--'
          }
          if(operate !== -1){
            item.id = item.subItemNews[operate].id
            item.operate = true
          }else{
            item.operate = false
          }
          return item
        })
        this.data = arr
        this.loading = false
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 上锁/解锁/恢复等操作方法
    OperationMethod(record,e){
      console.log(record,e)
      this.loading = true
      let data = new FormData()
      data.append("id", record.id)
      data.append("pv", e)
      console.log(data)
      doorSwitch(data)
      .then((res) => {
        console.log(res)
        const { errorCode, msg } = res
        if (errorCode == '00'){
          this.getTableData()
          this.$message.success(msg)
        }else if (errorCode == '02'){
          this.$message.error(msg)
          this.loading = false
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
#OperationStatus{
  height: 100%;
  padding: 0px 20px;
  overflow: auto;
}
</style>